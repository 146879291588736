import * as React from 'react';
import { DefaultLayout } from '../layouts/default';
import { Full } from '../components/layout/common/full';
import { CenterFill } from '../components/layout/common/center-fill';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';

const Text = styled.h1`
    font-size: 1.5rem;
    font-weight: bold;
    background-color: rgb(var(--color-highlight));
`;

const Button = styled.button`
    background: none;
    border: none;
    cursor: pointer;

    :hover {
        text-decoration: underline;
    }

    :focus {
        outline: none;
    }
`;

const NotFoundPage = () => {
    return (
        <DefaultLayout>
            <Full>
                <CenterFill>
                    <Text>&nbsp;404 Page Not Found&nbsp;</Text>
                    <Button
                        onClick={async () => {
                            await navigate(-1);
                        }}
                    >
                        Go to previous page
                    </Button>
                </CenterFill>
            </Full>
        </DefaultLayout>
    );
};

export default NotFoundPage;
